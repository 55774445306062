import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	captchaValid: {
		data: {},
		isLoading: false,
		hasCompleted: false,
		error: undefined
	}
};

export const captchaValidSlice = createSlice({
	name: 'captchaValid',
	initialState,
	reducers: {
		captchaValid: (state) => {
			state.captchaValid.isLoading = true;
			state.captchaValid.error = undefined;
		},
		captchaValidCompleted: (state, action) => {
			state.captchaValid.isLoading = false;
			state.captchaValid.hasCompleted = true;
			state.captchaValid.data = action.payload;
		},
		captchaValidFailed: (state, action) => {
			state.captchaValid.isLoading = false;
			state.captchaValid.error = action.payload;
		},
		captchaValidReset: (state) => {
			state.captchaValid = initialState.captchaValid;
		}
	}
});

export const {
	captchaValid,
	captchaValidCompleted,
	captchaValidFailed,
	captchaValidReset
} = captchaValidSlice.actions;

export default captchaValidSlice.reducer;
