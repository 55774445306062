import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';

const Faq = ({ t }) => {
	return (
		<div style={{ textAlign: 'left', fontSize: '14px' }}>
			<h2>{t('FAQHeader')}</h2>
			<p>
				<strong>{t('questions.question1')}</strong>
				<br /> <em>{t('questions.answer1')}</em>
			</p>
			<p>
				<strong>{t('questions.question2')}</strong>
				<br /> <em>{t('questions.answer2')}</em>
			</p>
			<p>
				<strong>{t('questions.question3')}</strong>
				<br /> <em>{t('questions.answer3')}</em>
			</p>
			<p>
				<strong>{t('questions.question4')}</strong>
				<br /> <em>{t('questions.answer4')}</em>
			</p>
			<p>
				<strong>{t('questions.question5')}</strong>
				<br /> <em>{t('questions.answer5')}</em>
			</p>
			<p>
				<strong>{t('questions.question6')}</strong>
				<br />{' '}
				<em>
					<Trans i18nKey='faq:questions.answer6'>
						A: Prior to receiving additional refunds, you must first complete
						and submit a Cancellation Request Form on the homepage. To do that,
						<Link to='/'>click here</Link>. Once your Cancellation Request Form
						is completed, follow the instructions shown on the Cancellation
						Confirmation Page.
					</Trans>
				</em>
			</p>
			<p>
				<strong>{t('questions.question7')}</strong>
				<br />{' '}
				<em>
					<Trans i18nKey='faq:questions.answer7'>
						A: There is no need to check as your membership has been cancelled.
						However, if you like, you can check on the status of your refund by
						visiting
						<Link to='http://www.memberstatus.com'>www.memberstatus.com</Link>
						after two business days. You will need your member/subscriber ID in
						order to check on the status of your refund. Or, you may call us
						toll free 7 a.m. - 10 p.m. Monday-Friday (CT) at 1-800-475-1942 to
						verify the status of your refund. Please allow one business day for
						our Customer Service systems to update.
					</Trans>
				</em>
			</p>
		</div>
	);
};

Faq.propTypes = {
	t: PropTypes.func
};

export default withTranslation('faq')(Faq);
