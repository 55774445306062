// import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';

import {
	captchaValid,
	captchaValidCompleted,
	captchaValidFailed
} from './captchaValidSlice';

const captchaValidEpic = (action$) =>
	action$.pipe(
		ofType(captchaValid),
		mergeMap(async (action) => {
			const captchaPayload = action.payload;
			// await axios.put(
			// 	`https://www.google.com/recaptcha/api/siteverify?secret=${SITE_SECRET}&response=${captchaPayload}`,
			// 	{
			// 		params: captchaPayload
			// 	}
			// );
			return {
				captchaPayload
			};
		}),
		switchMap((res) => [captchaValidCompleted(res)]),
		catchError((error, source) =>
			merge(of(captchaValidFailed(error.message)), source)
		)
	);

export default [captchaValidEpic];
